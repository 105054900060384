* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.4;
  color: #444;
  background: #fff;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
}

.inner {
  width: 100%;
  padding: 4rem 10px 4rem;
}

.form-container {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  border-radius: calc(0.5 * 100px);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.38);
  justify-content: space-evenly;
}

.input-text {
  font-size: 1rem;
  font-weight: 400;
  width: 85%;
  padding-right: 5px;
  padding-left: 10px;
  border-radius: calc(0.5 * 100px);
}

.input-text::placeholder {
  color: #000;
}

.input-submit {
  background: transparent;
  color: #5b5b5b;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 600;
  margin-right: 10px;
}

.input-submit svg {
  color: darkcyan;
  font-size: 30px;
  margin-top: 2px;
}

.input-text,
.input-submit {
  height: 45px;
  outline: none;
  border: none;
}

.active-link {
  color: orangered;
  text-decoration: underline;
}

/* sidebar Nav */

.navBar {
  position: relative;
  
}

.navBar button {
  position: fixed;
  left: 40px;
  top: 40px;
  z-index: 10;
  cursor: pointer;
}

.menuNav {
  overflow-y: scroll;
  list-style: none;
  position: fixed;
  top: 0;
  background: darkslateblue;
  left: 0;
  bottom: 0;
  height: 100vh;
  width: 0;
  overflow: hidden;
  max-width: 200px;
  opacity: 70%;
  transition: width 1s;
  z-index: 9;
}

.menuNav.showMenu {
  width: 100%;
}

a {
  display: block;
  padding: 10px 40px;
  text-decoration: none;
  color: rgb(127, 125, 125);
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

a:hover {
  color: plum;
}

a:active {
  background-color: plum
}

.menuNav li:first-child {
  margin-top: 7rem;
}
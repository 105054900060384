.bodyn {
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: url("hawaiiBackground.jpg");
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: auto;
}

.ninja {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  justify-content: center;
  font-size: x-large;
}

.diff {
  font-family: cursive;
  font-size: 100px;
  margin: 10px;
  -webkit-animation: myfirst 1s ; /* Chrome, Safari, Opera */
  animation: myfirst 1s ;
}

.h2n {
  margin-top: 0px;
  -webkit-animation: myfirst 2s ; /* Chrome, Safari, Opera */
  animation: myfirst 2s ;
}

.h3n {
  position: relative;
  color: white;
  text-align: center;
  justify-content: center;
  font-size: 40px;
  font-weight: 600;
  font-family: fantasy;  
  -webkit-animation: myfirst 10s ; /* Chrome, Safari, Opera */
  animation: myfirst 10s ;
  padding: 5%;
  margin-top: 1%;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes myfirst {
  from {opacity: 0.0;}
  to {opacity: 1;}
}
/* Standard syntax */
@keyframes myfirst {
  from {opacity: 0.0;}
  to {opacity: 1;}
}

.blurb {
  background-color:whitesmoke;
  opacity: 80%;
  padding: 20px;
  width: 70%;
  max-width: 800px;
  margin: 20%;
  border: 10px;
  border-style: solid;
  border-color: black;
  border-radius: 40px;
  
}

.blurb p {
  color: black;
  text-align: justify;
  font-size: large;
  text-indent: 30px; 
}

.wrappered {
  display: flex;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  padding: 1px;
}

.pic {
  background-image: url(Casey.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 197px;
  width: 220px;
  top: -25%;
  right: -20%;
  float: right;
  position: relative;
  border: 5px;
  border-style: solid;
  border-color: black;
  border-radius: 100%;
  padding: 10px;
}

